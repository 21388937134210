
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        














































































































































































.vod-promo,
[class*='vod-promo--'] {
  @include bg-color;
}

.vod-promo__inner {
  padding-top: $spacing * 2.5;
  padding-bottom: $spacing * 2.5;

  @include mq(m) {
    padding-top: $spacing * 4;
    padding-bottom: $spacing * 4;
  }

  @include mq(xl) {
    padding-top: $spacing * 10;
    padding-bottom: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.vod-promo__heading {
  display: flex;
  margin-bottom: 3rem;
}

.vod-promo__heading__inner {
  position: relative;
  margin: auto;
}

.vod-promo__heading__title {
  @extend %fw-medium;
  max-width: 70rem;
  margin: 0 auto 2rem;
  font-size: 4.8rem;
  text-align: center;

  @include mq(l) {
    margin: 0 auto;
  }
}

.vod-promo__heading__btn {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.vod-promo__heading__how {
  position: relative;
  display: block;
  margin-bottom: 2rem;

  @include mq(l) {
    position: absolute;
    top: 50%;
    right: 0;
    display: block;
    margin-bottom: 0;
    transform: translateX(110%) translateY(-50%);
  }

  a {
    @extend %fw-bold;
    display: block;
    max-width: 20rem;
    margin: auto;
    padding: 1.5rem 2rem;
    background-color: $c-pink-medium;
    color: $white;
    font-family: $ff-alt;
    font-size: 1.8rem;
    line-height: 1.2;
    text-decoration: none;
    border-radius: 8px;

    @include mq(l) {
      &::after {
        content: ' ';
        position: absolute;
        top: 50%;
        right: 100%;
        width: 0;
        height: 0;
        margin-top: -7px;
        border: solid transparent;
        border-width: 7px;
        border-color: rgba(136, 183, 213, 0);
        border-right-color: $c-pink-medium;
        pointer-events: none;
      }
    }

    &:hover {
      text-decoration: underline;
    }
  }
}

.vod-promo__cards,
.vod-promo__cards__expand {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end;

  @include mq(s) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 100rem;
    margin-inline: auto;
  }
}

.vod-promo__card {
  margin-bottom: 2rem;
  padding: 4rem 0;
  text-align: center;
  border-radius: 20px;
  width: 28rem;
  transition: all 0.3s $ease;

  @include mq(m) {
    width: 30rem;
  }

  @include mq(l) {
    // width: calc(100% / 3 - 2rem);
    // 4 films/lignes
    margin-bottom: 2rem;
    box-shadow: none;
    padding: 4rem 2rem;

    &:hover {
      transform: translateY(-10px);

      .vod-promo__card__action {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  &:hover {
    @extend %box-shadow;
  }

  // @include mq(xl) {
  //   width: calc(100% / 4 - 2rem);
  // }
}

.vod-promo__card__more-outer {
  display: flex;
  justify-content: center;
  width: 100%;
}

.vod-promo__cards__expand {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 0;
}

.vod-promo__card__category {
  @extend %fw-light;
  font-family: $ff-alt;
  font-size: 2.4rem;
  line-height: 1;
}

.vod-promo__card__title {
  @extend %fw-bold;
  font-family: $ff-alt;
  font-size: 2.4rem;
}

.vod-promo__card__picture {
  position: relative;
  margin: 3rem 0 7rem;

  @include mq(m) {
    margin: 3rem 0;
  }

  img {
    max-width: 100%;

    @include mq(s) {
      max-width: 20rem;
    }

    @include mq(m) {
      max-width: 22rem;
    }

    @include mq(xl) {
      max-width: 25.4rem;
      box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.2);
    }
  }
}

.vod-promo__card__action {
  transition: visibility 0s, opacity 0.3s $ease;

  @include mq(l) {
    visibility: hidden;
    opacity: 0;
  }

  > * {
    margin: 0 1rem;
  }
}

.vod-promo__card__price-box {
  position: absolute;
  bottom: -10%;
  right: 0;
  left: 0;
  max-width: 140px;
  margin: 0 auto;
  border-radius: 9px;

  @include mq(s) {
    top: 50%;
    bottom: auto;
    left: auto;
    right: 0;
    text-align: center;
    border-radius: 10px;
    transform: translateY(-50%);
  }

  ::v-deep {
    .price-box__price {
      color: $c-orange;
      font-size: 4.2rem;
    }

    .price-box__prev {
      display: inline-block;
      color: $c-gray-dark;
      font-size: 1.3rem;
      text-align: center;
    }

    .price-box__price > small {
      justify-content: center;
    }

    .price-box__price > small :last-child {
      color: $c-gray-dark;
      font-size: 1rem;
    }
  }
}

.vod-promo__card__more {
  font-size: 1.4rem;
}

.vod-promo__link-outer {
  text-align: center;
}
