
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        
































































































::v-deep .vod-highlight__infos-outer {
  @include mq($until: m) {
    flex-direction: column;
  }
}
/* stylelint-disable selector-max-combinators, selector-max-compound-selectors */
.entertain-yourself {
  overflow: hidden;

  ::v-deep .cta__btn {
    position: relative;
    z-index: 100;
    align-items: center;

    .icon {
      margin-right: $spacing * 0.5;
    }

    .shared-icons-calendar,
    .shared-ui-streaming {
      width: 2.4rem;
      height: 2.4rem;
      fill: $c-pink-medium;
    }
  }

  ::v-deep .video-player {
    display: flex;
    flex-direction: column;
    padding-top: 0 !important; /* stylelint-disable-line declaration-no-important */

    .video__legend {
      order: 2;
    }
  }

  ::v-deep .card__picture__logo {
    top: $spacing !important; /* stylelint-disable-line declaration-no-important */
    left: $spacing !important; /* stylelint-disable-line declaration-no-important */
    max-width: 6rem;
    max-height: 6rem;

    @include mq(xxl) {
      top: $spacing !important; /* stylelint-disable-line declaration-no-important */
      left: $spacing !important; /* stylelint-disable-line declaration-no-important */
      max-width: 8rem;
      max-height: 8rem;
    }
  }

  // TEMP REMOVAL FOR XMAS
  ::v-deep .agenda__picture__logo {
    display: none;
  }
}

.entertain-yourself__header {
  ::v-deep .hero__slides__item__icon {
    display: flex;

    .icon {
      max-width: 30rem;
      max-height: none;
    }

    img {
      height: auto;
    }
  }

  @include mq(m) {
    ::v-deep .hero__slides__item__icon {
      .icon {
        max-width: 50rem;
      }
    }
  }

  @include mq(l) {
    ::v-deep .hero__slides__item__icon {
      .icon {
        max-width: 67rem;
        margin-bottom: 2rem;
      }
    }
  }
}

.entertain-yourself__warner {
  ::v-deep {
    .vod-highlight__infos {
      @include mq(m) {
        margin: 15rem 0;
      }
    }
  }
}

.vod-slider {
  padding-top: 0;
  padding-bottom: 0;
  background-color: $c-white-dark;

  ::v-deep {
    .voo-slider__title {
      margin-top: 0;
      font-weight: 700;
    }
  }
}
