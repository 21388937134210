
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        













































































































































































































































































































































/* stylelint-disable declaration-no-important, max-nesting-depth, no-descending-specificity */
.agenda,
[class*='agenda--'] {
  @extend %box-shadow;

  position: relative;

  &[class*='--dark'] {
    .agenda__infos {
      color: $white;
      background-color: $c-blue-dark;
    }
  }

  &.has-link {
    cursor: pointer;

    &:hover {
      .agenda__picture,
      [class*='card__picture--'] {
        transform: scale(1.1);
      }
    }
  }

  @include mq(m) {
    display: flex;
    flex-direction: column;

    &[class*='--full'],
    [class*='--full'] & {
      flex-direction: row;
      min-height: 30rem;
    }

    &[class*='--reverse'],
    [class*='--full--reverse'] & {
      flex-direction: row-reverse;
    }
  }
}

.agenda__link {
  @include get-all-space;

  z-index: layer(high);
}

.agenda__picture-outer,
[class*='card__picture-outer--'] {
  @include aspect-ratio(16, 9);

  flex-shrink: 0;
  overflow: hidden;

  &[class*='--with-shadow'] {
    &::before {
      content: '';
      position: relative;
      z-index: layer(low);
      display: block;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        135deg,
        rgba($c-blue-dark, 1) 0%,
        rgba($c-blue-dark, 0) 50%
      );
    }

    @include mq(m) {
      [class*='--full'] & {
        @include aspect-ratio(4, 3);
      }

      [class*='--full--reverse'] & {
        &::before {
          background: linear-gradient(
            45deg,
            rgba($black, 0) 0%,
            rgba($black, 0) 50%,
            rgba($black, 0.9) 100%
          );
        }
      }
    }
  }

  @include mq(m) {
    [class*='--full'] & {
      width: inner-col(12, 5);
      height: auto;
    }
  }
}

.agenda__picture {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s $ease-out;
}

.agenda__picture__logo {
  position: absolute;
  z-index: layer(high);
  top: $spacing !important;
  left: $spacing !important;
  max-width: 8rem;
  max-height: 8rem;

  @include mq(m) {
    [class*='--full--reverse'] & {
      right: $spacing * 2 !important;
      left: auto !important;
    }
  }

  @include mq(xxl) {
    top: $spacing * 2 !important;
    left: $spacing * 2 !important;
    max-width: 10rem;
    max-height: 10rem;
  }
}

.agenda__infos {
  position: relative;
  padding: $spacing * 1.5;
  background-color: $white;

  ::v-deep .btn {
    margin-top: 2rem;
  }

  ::v-deep .wysiwyg {
    margin-bottom: 2rem;

    p {
      margin-top: 0;
      margin-bottom: 1rem;
    }
  }

  @include mq(m) {
    [class*='--full'] & {
      width: inner-col(12, 7);
      padding: $spacing * 2;
    }
  }

  @include mq(l) {
    padding: $spacing * 2;

    [class*='--full'] & {
      // price box gets to close to content on NL
      padding: $spacing * 3 $spacing * 3 $spacing * 3 $spacing * 3.5;
    }

    [class*='--full--reverse'] & {
      padding: $spacing * 3;
    }
  }
}

.agenda__infos__icon {
  height: 3rem;
  margin-bottom: $spacing * 0.5;
  fill: $c-pink-medium;

  @include mq(m) {
    height: 5rem;
  }
}

.agenda__infos__price-box {
  position: absolute;
  z-index: layer(low);
  top: 0;
  right: $spacing * 2;
  transform: translateY(-50%);

  em {
    font-style: normal;
  }

  @include mq(m) {
    [class*='--full'] & {
      top: $spacing * 2;
      right: auto;
      bottom: auto;
      left: 0;
      transform: translateX(-80%);
    }

    [class*='--full--reverse'] & {
      top: $spacing * 2;
      right: 0;
      left: auto;
      transform: translateX(50%);
    }
  }

  @include mq(l) {
    [class*='--full'] & {
      transform: translateX(-50%);
    }

    [class*='--full--reverse'] & {
      transform: translateX(50%);
    }
  }
}

.agenda__infos__title {
  @extend %fw-medium;

  margin: 0 0 $spacing * 0.5;

  ::v-deep .agendas & {
    font-size: 1.8em / $body-text-s * 1em;
    line-height: 1.25;

    @include mq(m) {
      font-size: 2.2em / $body-text-m * 1em;
    }

    @include mq(l) {
      font-size: 2.4em / $body-text-l * 1em;
    }
  }
}

.agenda__infos__subtitle {
  margin: $spacing / 2 0;
}

.agenda__infos__highlight {
  @extend %fw-medium;

  margin-bottom: $spacing * 1.5;
}

.agenda__infos__link {
  margin-top: $spacing;
}

[class*='btn--'] + .agenda__infos__link {
  margin-left: 2rem;
}

.agenda__infos__cta {
  display: inline-flex;
  align-items: center;
  margin: 0 1.5rem 2rem 0;

  @include mq(m) {
    display: flex;
  }
}
