
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        

















































































































































































.entertain-title {
  padding-bottom: 0 !important;
}

.entertain-title__header {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;

  @include mq($until: m) {
    .has-video & {
      flex-direction: column;
    }
  }

  @include mq(m) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.entertain-title__content {
  margin: 10rem auto 5rem;
  text-align: center;

  .has-video & {
    margin: 0 auto 5rem;
  }

  @include mq(m) {
    flex: 1;
  }
}

.entertain-title__title {
  @extend %fw-medium;
  @include fluid-type(24, 48);

  display: block;
  margin-bottom: 1rem;
  font-family: $ff-alt;
  line-height: 1.1;

  @include mq(m) {
    margin-bottom: 3rem;
  }
}

.entertain-title__logo {
  width: 100%;
  max-width: 23rem;

  @include mq(l) {
    max-width: 43rem;
  }
}

.entertain-title__speedlines {
  z-index: -1;
  height: 100%;
  transform: scale(1.5);
}

.entertain-title__bubble-one,
.entertain-title__bubble-two,
.entertain-title__bubble-three {
  border-radius: 50%;
  display: block;
  position: absolute;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.entertain-title__bubbles-left {
  position: relative;
  margin: auto;
  height: 32rem;
  width: 30rem;
  transform: scale(0.8);
  transform-origin: center center;

  @include mq(m) {
    right: 5rem;
    transform: scale(0.6);
  }

  @include mq(l) {
    right: 10rem;
    transform: none;
  }

  .entertain-title__heart-filled {
    fill: $c-cyan-contrast;
    position: absolute;
    left: 13.3rem;
    top: -4rem;
    transform: rotate(-7.4deg);
  }

  .entertain-title__heart-voo {
    fill: $c-pink-medium;
    position: absolute;
    left: 14.6rem;
    top: 20rem;
    transform: rotate(-11.8deg);
  }

  .entertain-title__bubble-one {
    width: 12.2rem;
    height: 12.2rem;
  }

  .entertain-title__bubble-two {
    width: 15.6rem;
    height: 15.6rem;
    left: 8.72rem;
    top: 5.8rem;
  }
}

.entertain-title__bubbles-right {
  display: none;
  position: relative;
  margin: auto 0;
  height: 32rem;
  width: 30rem;
  transform: scale(0.8);
  transform-origin: center center;

  @include mq(m) {
    left: 5rem;
    display: block;
    transform: scale(0.6);
  }

  @include mq(l) {
    left: 10rem;
    transform: none;
  }

  .entertain-title__heart-filled {
    fill: $c-orange;
    position: absolute;
    left: 13.3rem;
    top: -8rem;
    transform: rotate(7.9deg);
  }

  .entertain-title__heart-voo {
    fill: $c-pink-medium;
    position: absolute;
    left: 0.4rem;
    top: 8.4rem;
    transform: rotate(5.8deg);
  }

  .entertain-title__bubble-three {
    width: 14.6rem;
    height: 14.6rem;
  }
}

// .entertain-title__christmas {
//   background-color: $c-light-gray;

//   ::v-deep {
//     .section-row__picture {
//       position: relative;
//       overflow: hidden;

//       img {
//         max-height: none;
//         margin: 0;
//       }

//       &::before {
//         content: '';
//         display: block;
//         width: 100%;
//         padding-top: (9 / 16) * 100%;
//       }
//     }

//     .section-row__speedlines {
//       height: 150%;
//     }
//   }

//   @include mq($until: l) {
//     padding-bottom: 0 !important;

//     ::v-deep {
//       .section-row__inner {
//         display: flex;
//         flex-direction: column-reverse;
//       }

//       .section-row__picture {
//         width: 100%;
//         margin-bottom: 0;
//       }
//     }
//   }

//   @include mq(l) {
//     padding-bottom: 0 !important;

//     ::v-deep {
//       .speedlines {
//         width: 100%;
//       }

//       .section-row__content {
//         padding-top: $spacing * 6;
//         padding-bottom: $spacing * 6;

//         @include mq(l) {
//           width: col(5);
//         }
//       }

//       .section-row__picture {
//         @include mq(l) {
//           width: col(8);
//           margin-left: col(-2);
//         }
//       }
//     }
//   }
// }

// .entertain-title__christmas__play {
//   @include get-all-space;

//   z-index: 10;
//   display: flex;
//   justify-content: center;
//   align-items: center;

//   img {
//     width: 10rem;
//     transition: transform 0.3s $ease;
//   }

//   &:hover {
//     cursor: pointer;

//     img {
//       transform: scale(1.05);
//     }
//   }
// }

.entertain-title__christmas {
  padding-top: $spacing * 4 !important;
  background: url('/assets/images/sparkles.png') top center no-repeat;
  background-color: $c-blue-dark;
  background-size: cover;
  color: white;

  ::v-deep {
    .section-row__content__logo {
      img {
        max-width: 12.6rem;
      }
    }

    .section-row__content__subtitle {
      font-size: 2.4rem;
    }

    .section-row__picture {
      position: relative;
      overflow: hidden;

      img {
        max-height: none;
        margin: 0;
      }

      &::before {
        content: '';
        display: block;
        width: 100%;
        padding-top: (9 / 16) * 100%;
      }
    }

    .section-row__speedlines {
      height: 150%;
    }
  }

  @include mq($until: l) {
    padding-bottom: 0 !important;

    .section-row__inner {
      display: flex;
      flex-direction: column-reverse;
    }

    .section-row__picture {
      width: 100%;
      margin-bottom: 0;
    }
  }

  @include mq(l) {
    padding-bottom: 0 !important;

    .section-row__speedlines {
      left: 0;
      width: 100%;
    }

    .section-row__content {
      padding-bottom: $spacing * 4;
    }
  }
}

.entertain-title__christmas__play {
  @include get-all-space;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 10rem;
    transition: transform 0.3s $ease;
  }

  &:hover {
    cursor: pointer;

    img {
      transform: scale(1.05);
    }
  }
}

.entertain-title__link {
  margin-top: $spacing * 2;
}

.entertain-title__link-popup {
  margin-top: $spacing * 2;
  cursor: pointer;
}

.entertain-yourself__video {
  padding-top: 0 !important; /* stylelint-disable-line declaration-no-important */
  padding-bottom: $spacing * 2;
}
